// ES6
import ReactMapboxGl, { Layer, Feature } from 'react-mapbox-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import React, { Component } from "react";
import { Marker } from "react-mapbox-gl";
import WarelmaLogo from 'src/images/warelma/logo.png';


const AnyReactComponent = ({ text }) => <div>{text}</div>;
const Map = ReactMapboxGl({
    accessToken:
        'pk.eyJ1Ijoiam9za29rbyIsImEiOiJja2k0bHR2c2sxbGYxMnltc2tuMWJyMGZyIn0.M1ZKrdr6UGuQ5PgAA6_jZA',
    scrollZoom: false

});

class SimpleMap extends Component {

    render() {
        return (
                <Map
                    style="mapbox://styles/mapbox/streets-v9"
                    containerStyle={{
                        height: '400px',
                        width: '100%'
                    }}
                    center={[4.308290, 51.360600]}
                >
                    <a target="_blank" href="https://www.google.com/maps/dir//Noordland+5A,+2040+Antwerpen">
                        <Marker
                            coordinates={[4.308290, 51.360600]}
                            anchor="bottom">
                            <div className={"px-3 py-2 bg-white flex rounded items-center content-center shadow mb-3"}>
                                <img src={WarelmaLogo} className={"w-8"}/>
                                <div className="logo-small text-primary  font-black font-family-1 pl-2">WARELMA</div>
                                <div style={{transform: 'translate(-52px, 26px)'}}>
                                    <svg height="20" width="20" viewBox="0, 0, 20, 20" className="text-white">
                                        <polygon points="0,0 20,0 10,20" className="triangle"
                                                 fill="currentColor"></polygon>
                                    </svg>
                                </div>
                            </div>
                        </Marker>
                    </a>
                </Map>
        );
    }
}

export default SimpleMap;